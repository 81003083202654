export default [
    { 
        path: '/inscrivez-vous/seances/:id/:date', 
        name: 'meeting_registration_landing',
        component: () => import('/src/pages/PageMeetingRegistration.vue'),
    },
    { 
        path: '/inscrivez-vous/activites-gratuites/:id/:date', 
        name: 'registration_landing_free_event',
        component: () => import('/src/pages/PageFreeEventRegistration.vue'),
    },
    { 
        path: '/inscrivez-vous/service-andc-aide', 
        name: 'registration_landing_andc_help',
        component: () => import('/src/pages/PageRegisterAndcHelp.vue'),
    },
];