export default [
    { 
        path: '/grand-public', 
        redirect: '/grand-public/tout-savoir-sur-loffre-grand-public'
    },
    {
        path: '/grand-public/tout-savoir-sur-loffre-grand-public',
        name: 'mainstream_landing',
        component: () => import('/src/pages/PageMainstream.vue'),
    },
    {
        path: '/grand-public/programmation',
        name: 'mainstream_calendar',
        component: () => import('/src/pages/PageMainstreamCalendar.vue'),
    },
    {
        path: '/grand-public/:type/:slug',
        name: 'mainstream_event',
        component: () => import('/src/pages/PageMainstreamEvent.vue'),
    },
    {
        path: '/grand-public/service-de-relation-daide-andc-aide',
        name: 'mainstream_andc_help',
        component: () => import('/src/pages/PageMainstreamAndcHelp.vue'),
    },
    {
        path: '/grand-public/trouver-un-coach-en-entreprise',
        name: 'mainstream_coach',
        component: () => import('/src/pages/PageMainstreamCoach.vue'),
    },
    { 
        path: '/grand-public/coaching-en-entreprise/seances-dinformations-pour-le-coaching-en-entreprise-par-landc', 
        redirect: '/grand-public/trouver-un-coach-en-entreprise'
    },
    { 
        path: '/grand-public/formation-de-base/seances-dinformations-pour-la-formation-professionnelle-a-landc', 
        redirect: '/devenir-therapeute/seances-dinformation-gratuites'
    },
    {
        path: '/grand-public/trouver-un-coach-en-entreprise/inscription',
        name: 'mainstream_coach_registration',
        component: () => import('/src/pages/PageMainstreamCoachRegistration.vue'),
    }
];
