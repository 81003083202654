import { defineStore } from "pinia";
import { useSessionStorage } from '@vueuse/core'
import { useQuery } from "@vue/apollo-composable";
import { fetchNavigation, fetchCoreSettings, submitSearch, fetchEventTypes } from "@/graphql/core";

import dayjs from 'dayjs';
import 'dayjs/locale/fr';

import { useCart } from '@/stores/cart';

export const useCore = defineStore('core', {
    state: () => {
        return {
            cookies: undefined,
            region: 'north_america',
            show_region_dialog: false,
            show_cookies_dialog: true,
            show_burger: false,
            scholar_year: undefined,

            individuel: true,
            couple: true,
            enfant: true,
            coaching: true,
            mourning: true,

            search: {
                value: '',
                results: {
                    articles: [],
                    books: [],
                    events: [],
                    pages: [],
                    trainings: [],
                },
                no_results: false,
            },
            
            navigation: {
                main: [],
                secondary: [],

                show_about_menu: false,
                show_andc_menu: false,
                show_become_tra_menu: false,
                show_personal_development_menu: false,
                show_graduates_menu: false,

                mobile_show_about: false,
                mobile_show_andc: false,
                mobile_show_training: false,
                mobile_show_mainstream: false,
                mobile_show_graduates: false,
            },

            types: []
        };
    },

    // Getters
    getters: {
        getter_show_about_menu: (state) => {
            return state.navigation.show_about_menu;
        },
        getter_show_andc_menu: (state) => {
            return state.navigation.show_andc_menu;
        },
        getter_show_become_tra_menu: (state) => {
            return state.navigation.show_become_tra_menu;
        },
        getter_show_personal_development_menu: (state) => {
            return state.navigation.show_personal_development_menu;
        },
        getter_show_graduates_menu: (state) => {
            return state.navigation.show_graduates_menu;
        },
        getter_search_results_count: (state) => {
            return state.search.results.trainings.length + state.search.results.events.length + state.search.results.books.length + state.search.results.articles.length + state.search.results.pages.length;
        },
    },

    // Actions
    actions: {
        showSubMenu (section) {
            if (section == 'about') {
                this.navigation.mobile_show_about = !this.navigation.mobile_show_about;
            }

            if (section == 'andc') {
                this.navigation.mobile_show_andc = !this.navigation.mobile_show_andc;
            }

            if (section == 'training') {
                this.navigation.mobile_show_training = !this.navigation.mobile_show_training;
            }

            if (section == 'mainstream') {
                this.navigation.mobile_show_mainstream = !this.navigation.mobile_show_mainstream;
            }

            if (section == 'graduates') {
                this.navigation.mobile_show_graduates = !this.navigation.mobile_show_graduates;
            }
        },

        async fetchNavigation() {
            return new Promise((resolve, reject) => {
                const query = useQuery(fetchNavigation);

                query.onError((error) => reject(error));
                
                query.onResult((result) => {     
                    this.navigation.main = result.data.navigation.main;
                    this.navigation.secondary = result.data.navigation.secondary;

                    resolve();
                });
            });
        },

        async fetchCoreSettings() {
            return new Promise((resolve, reject) => {         
                
                // If we already have the data, don't fetch it again
                if (useSessionStorage('scholar_year').value !== undefined) {
                    this.scholar_year = useSessionStorage('scholar_year').value;

                    resolve();
                    return;
                }

                const query = useQuery(fetchCoreSettings);

                query.onError((error) => reject(error));
                
                query.onResult((result) => {  
                    const scholar_year = result.data.coreSettings.filter(setting => setting.key == 'scholar_year').shift();
                    this.scholar_year = scholar_year.value;

                    useSessionStorage('scholar_year', scholar_year.value);

                    resolve();
                });
            });
        },

        async fetchAndcHelpSettings() {
            return new Promise((resolve, reject) => {         
                const query = useQuery(fetchCoreSettings);

                query.onError((error) => reject(error));
                
                query.onResult((result) => {  

                    const andc = result.data.coreSettings.filter(setting => setting.key == 'andchelp').shift();

                    const andchelp = JSON.parse(andc.value);

                    this.individuel = andchelp.individuel;
                    this.couple = andchelp.couple;
                    this.enfant = andchelp.enfant;
                    this.coaching = andchelp.coaching;
                    this.mourning = andchelp.mourning;

                    resolve();
                });
            });
        },

        async fetchTypes() {
            return new Promise((resolve, reject) => {         
                const query = useQuery(fetchEventTypes);

                query.onError((error) => reject(error));
                
                query.onResult((result) => {  
                    this.types = result.data.types;

                    resolve();
                });
            });
        },

        amount(cents, num_decimal = 0) {
            return (cents / 100)
                .toLocaleString('fr-CA', {
                    style: 'currency', 
                    currency: this.region === 'north_america' ? 'CAD' : 'EUR', 
                    maximumFractionDigits: num_decimal
                });
        },

        amountWithTaxes(cents) {
            if (this.region === 'europe') {
                return {
                    tps: 0,
                    tvq: 0,
                    subtotal: cents,
                    total: cents,
                };
            }

            var tps = parseInt((cents * 0.05).toFixed());
            var tvq = parseInt((cents * 0.09975).toFixed());

            var total = cents + tps + tvq;

            return {
                tps: tps,
                tvq: tvq,
                subtotal: cents,
                total: total,
            };
        },

        eventRegistrationFee(event) {
            if (this.region === 'europe') {
                const fee = this.amountWithTaxes(event.registration_fee_euro);
                return this.amount(fee.total, 2) + '<span class="text-base"> TTC</span>';
            }
            
            return this.amount(event.registration_fee, 2) + '<span class="text-base"> avant taxes</span>';
        },

        eventPrice(event, total = false) {
            if (this.region === 'europe') {
                var price = this.amountWithTaxes(event.price_euro);

                if (total) {

                    var price = event.registration_fee_euro + event.price_euro;

                    if (event.price > 0)
                    {
                        price = event.price_euro;
                    }

                    price = this.amountWithTaxes(price);
                }

                return this.amount(price.total, 2) + '<span class="text-base"> TTC</span>';
            }

            var price = event.registration_fee + event.price;

            if (event.price > 0)
            {
                price = event.price;
            }
            
            return this.amount(price, 2) + '<span class="text-base"> avant taxes</span>';
        },

        trainingPrice(training) {
            var total = training.total;
            var taxes = 'avant taxes';

            if (this.region === 'europe') {
                total = training.total_euro;
                taxes = 'TTC';
            } 
            
            return this.amount(total, 2) + '<span class="text-base"> ' + taxes + '</span>';
        },

        parseAmount(amount, num_decimal) {
            var amount = this.amountWithTaxes(amount);
            return this.amount(amount.total, num_decimal);
        },

        resolveItemDate(date) {
            if (date.is_on_2_days) {
                const first_day_month = dayjs(date.starts_at).locale('fr').format('MM');
                const second_day_month = dayjs(date.starts_at).locale('fr').add(1, 'day').format('MM');

                if (first_day_month !== second_day_month) {
                    return dayjs(date.starts_at).locale('fr').format('DD MMMM') + ' / ' + dayjs(date.starts_at).locale('fr').add(1, 'day').format('DD MMMM YYYY');
                }

                return dayjs(date.starts_at).locale('fr').format('DD') + ' / ' + dayjs(date.starts_at).locale('fr').add(1, 'day').format('DD MMMM YYYY');
            }

            return dayjs(date.starts_at).locale('fr').format('DD MMMM YYYY');
        },

        async submitSearch() {
            return new Promise((resolve, reject) => {                
                const query = useQuery(submitSearch, { value: this.search.value });

                query.onError((error) => reject(error));
                
                query.onResult((result) => {       
                    this.search.results = result.data.search;

                    if (this.search.results.articles.length === 0 &&
                        this.search.results.books.length === 0 &&
                        this.search.results.events.length === 0 &&
                        this.search.results.pages.length === 0 &&
                        this.search.results.trainings.length === 0
                    ) {
                        this.search.no_results = true;
                    }
                    
                    resolve();
                });
            });
        },

        setCookies(answer) {
            this.show_cookies_dialog = false;
            this.cookies = answer == true ? true : false;
            localStorage.setItem('cookies', this.cookies);
        },

        setRegion(region) {
            this.show_region_dialog = !this.show_region_dialog;

            this.region = region;
            localStorage.setItem('region', region);

            const cart = useCart();
            cart.data.items = []; 
            cart.data.items_count = 0; 

            localStorage.removeItem('cart');
            localStorage.removeItem('registration');

            window.location.replace('/');
        },

        toggleMegaMenu(menu, boolean) {
            this.navigation.show_about_menu = false;
            this.navigation.show_andc_menu = false;
            this.navigation.show_become_tra_menu = false;
            this.navigation.show_personal_development_menu = false;
            this.navigation.show_graduates_menu = false;
            
            if (menu == 'about') {
                this.navigation.show_about_menu = boolean;
            }

            if (menu == 'andc') {
                this.navigation.show_andc_menu = boolean;
            }

            if (menu == 'become_tra') {
                this.navigation.show_become_tra_menu = boolean;
            }

            if (menu == 'personal_development') {
                this.navigation.show_personal_development_menu = boolean;
            }

            if (menu == 'graduates') {
                this.navigation.show_graduates_menu = boolean;
            }
        },

        toggleRegionDialog() {
            this.show_region_dialog = !this.show_region_dialog;
        },

        toggleBurgerMenu() {
            this.show_burger = !this.show_burger;
        }
    },
});
