import { createRouter, createWebHistory } from 'vue-router';

import about from './about';
import andc from './andc';
import cart from './cart';
import graduates from './graduates';
import mainstream from './mainstream';
import registrations from './registrations';
import redirects from './redirects';
import trainings from './trainings';

const core = [
    {
        path: '/',
        name: 'home',
        component: () => import('/src/pages/PageHome.vue'),
    },
    {
        path: '/recherche',
        name: 'search',
        component: () => import('/src/pages/PageSearch.vue'),
    },
    {
        path: '/infolettre',
        name: 'newsletter',
        component: () => import('/src/pages/PageNewsletter.vue'),
    },
    {
        path: '/nous-joindre',
        name: 'contact_us',
        component: () => import('/src/pages/PageContactUs.vue'),
    },
    {
        path: '/politique-de-confidentialite',
        name: 'confidentiality',
        component: () => import('/src/pages/PageConfidentiality.vue'),
    },
    {
        path: '/approbation-de-la-politique-de-confidentialite',
        name: 'confidentiality_approval',
        component: () => import('/src/pages/PageConfidentialityApproval.vue'),
    },
    {
        path: '/annulations-et-remboursements',
        name: 'cancelation',
        component: () => import('/src/pages/PageCancelation.vue'),
    },
    {
        path: '/politique-dinclusion',
        name: 'inclusion',
        component: () => import('/src/pages/PageInclusion.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('/src/pages/PageNotFound.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: [
        ...core,
        ...about,
        ...andc,
        ...cart,
        ...graduates,
        ...mainstream,
        ...redirects,
        ...registrations,
        ...trainings,
    ],
});

export default router;
