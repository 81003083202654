export default [
    { 
        path: '/a-propos-du-cram-eif', 
        redirect: '/a-propos-du-cram-eif/tout-savoir-sur-le-cram'
    },
    {
        path: '/a-propos-du-cram-eif/tout-savoir-sur-le-cram',
        name: 'about_landing',
        component: () => import('/src/pages/PageAbout.vue'),
    },
    {
        path: '/a-propos-du-cram-eif/accreditations-de-lecole',
        name: 'about_accreditations',
        component: () => import('/src/pages/PageAboutAccreditations.vue'),
    },
    {
        path: '/a-propos-du-cram-eif/actualites',
        name: 'about_articles',
        component: () => import('/src/pages/PageAboutArticles.vue'),
    },
    {
        path: '/a-propos-du-cram-eif/actualites/:slug',
        name: 'about_article',
        component: () => import('/src/pages/PageAboutArticle.vue'),
    },
    {
        path: '/a-propos-du-cram-eif/les-editions-du-cram',
        name: 'about_editions_cram',
        component: () => import('/src/pages/PageAboutEditionsCRAM.vue'),
    },
    {
        path: '/a-propos-du-cram-eif/rencontrer-notre-equipe',
        name: 'about_team',
        component: () => import('/src/pages/PageAboutTeam.vue'),
    },
];
