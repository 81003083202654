export default [
    { 
        path: '/a-propos-du-cram/lapproche-non-directive-creatrice-andc', 
        redirect: '/l-approche-andc/tout-savoir-sur-landc'
    },
    { 
        path: '/a-propos-du-cram/les-fondateurs', 
        redirect: '/a-propos-du-cram-eif/tout-savoir-sur-le-cram'
    },
    { 
        path: '/a-propos-du-cram/la-direction', 
        redirect: '/a-propos-du-cram-eif/rencontrer-notre-equipe'
    },
    { 
        path: '/a-propos-du-cram/lequipe', 
        redirect: '/a-propos-du-cram-eif/rencontrer-notre-equipe'
    },
    { 
        path: '/a-propos-du-cram/lecole-et-sa-mission', 
        redirect: '/a-propos-du-cram-eif/tout-savoir-sur-le-cram'
    },
    { 
        path: '/a-propos-du-cram/legalite-de-la-pratique', 
        redirect: '/devenir-therapeute/legalite-de-la-pratique-de-tra'
    },
    { 
        path: '/a-propos-du-cram/temoignages-de-nos-eleves', 
        redirect: '/a-propos-du-cram-eif/tout-savoir-sur-le-cram'
    },
    { 
        path: '/a-propos-du-cram/les-editions-du-cram', 
        redirect: '/a-propos-du-cram-eif/les-editions-du-cram'
    },

    { 
        path: '/formation-en-relation-d-aide/a-propos-de-la-formation', 
        redirect: '/devenir-therapeute/tout-savoir-sur-la-formation'
    },
    { 
        path: '/formation-en-relation-d-aide/a-propos-de-la-formation/:slug', 
        redirect: '/devenir-therapeute/tout-savoir-sur-la-formation'
    },
    { 
        path: '/formation-en-relation-d-aide/a-propos-de-la-formation/seances-dinformation', 
        redirect: '/devenir-therapeute/seances-dinformation-gratuites'
    },
    { 
        path: '/coaching-en-entreprise', 
        redirect: '/grand-public/trouver-un-coach-en-entreprise'
    },
    { 
        path: '/coaching-en-entreprise/:slug', 
        redirect: '/grand-public/trouver-un-coach-en-entreprise'
    },
    { 
        path: '/deuils-et-pertes', 
        redirect: '/grand-public/programmation'
    },
    { 
        path: '/deuils-et-pertes/formations-pour-therapeutes', 
        redirect: '/diplomes-du-cram/catalogue-complet-des-formations'
    },
    { 
        path: '/deuils-et-pertes/:slug', 
        redirect: '/grand-public/programmation'
    },
    { 
        path: '/developpement-personnel', 
        redirect: '/grand-public/tout-savoir-sur-loffre-grand-public'
    },
    { 
        path: '/developpement-personnel/:section/:slug', 
        redirect: '/grand-public/programmation'
    },
    { 
        path: '/inscrivez-vous', 
        redirect: '/grand-public/programmation'
    },
    { 
        path: '/calendrier-des-cours', 
        redirect: '/devenir-therapeute/calendrier-de-la-session-en-cours'
    },
    { 
        path: '/zone-des-diplomes', 
        redirect: '/diplomes-du-cram/tout-savoir-sur-loffre-aux-diplomes'
    },
    { 
        path: '/zone-des-diplomes/sondages', 
        redirect: '/diplomes-du-cram/sondage'
    },
    { 
        path: '/zone-des-diplomes/:slug', 
        redirect: '/diplomes-du-cram/tout-savoir-sur-loffre-aux-diplomes'
    },
    { 
        path: '/service-andc-aide', 
        redirect: '/grand-public/service-de-relation-daide-andc-aide'
    },
    { 
        path: '/activites-gratuites', 
        redirect: '/grand-public/programmation'
    },
    { 
        path: '/actualites', 
        redirect: '/a-propos-du-cram-eif/actualites'
    },
    { 
        path: '/faq', 
        redirect: '/a-propos-du-cram-eif/tout-savoir-sur-le-cram'
    },
    { 
        path: '/credits-dimpots', 
        redirect: '/devenir-therapeute/tout-savoir-sur-la-formation'
    },
];