export default [
    { 
        path: '/devenir-therapeute', 
        redirect: '/devenir-therapeute/tout-savoir-sur-la-formation'
    },
    { 
        path: '/devenir-therapeute/formation-de-base', 
        redirect: '/devenir-therapeute/tout-savoir-sur-la-formation'
    },
    {
        path: '/devenir-therapeute/tout-savoir-sur-la-formation',
        name: 'trainings_landing',
        component: () => import('/src/pages/PageTrainings.vue'),
    },
    {
        path: '/devenir-therapeute/calendrier-de-la-session-en-cours',
        name: 'trainings_calendar',
        component: () => import('/src/pages/PageTrainingsCalendar.vue'),
    },
    {
        path: '/devenir-therapeute/formation-de-base/certificat-1',
        name: 'trainings_basis_1',
        component: () => import('/src/pages/PageTrainingsBasis.vue'),
    },
    {
        path: '/devenir-therapeute/formation-de-base/certificat-2',
        name: 'trainings_basis_2',
        component: () => import('/src/pages/PageTrainingsBasis.vue'),
    },
    {
        path: '/devenir-therapeute/formation-de-base/certificat-3',
        name: 'trainings_basis_3',
        component: () => import('/src/pages/PageTrainingsBasis.vue'),
    },
    {
        path: '/devenir-therapeute/seances-dinformation-gratuites',
        name: 'trainings_meetings',
        component: () => import('/src/pages/PageTrainingsMeetings.vue'),
    },
    {
        path: '/devenir-therapeute/inscription-et-admission',
        name: 'trainings_registration',
        component: () => import('/src/pages/PageTrainingsRegistration.vue'),
    },
    {
        path: '/devenir-therapeute/legalite-de-la-pratique-de-tra',
        name: 'trainings_legality',
        component: () => import('/src/pages/PageTrainingsLegality.vue'),
    },
];