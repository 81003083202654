import { defineStore } from "pinia";
import router from "../routes/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { createPaymentIntent, confirmPayment, fetchCartItemsPrices } from "@/graphql/registrations";
import { useCore } from '@/stores/core';

export const useCart = defineStore('cart', {
    state: () => {
        return {
            data: {
                client_secret: null,
                items: [],
                items_count: 0,
                message: null,
            },

            errors: {
                show_error_alert: false,
            },

            steps: {
                num: 1,
                step1: true,
                step2: false,
                step3: false,
                step4: false,
            },
        };
    },

    // Getters
    getters: {
        subtotal(state) { 
            const core = useCore();

            if (core.region === 'europe') {
                return this.calculateAmount('price') + this.tps + this.tvq; 
            }
            
            return this.calculateAmount('price');
        },

        tps(state) { 
            return this.calculateAmount('tps');
        },

        tvq(state) { 
            return this.calculateAmount('tvq');
        },
                        
        total(state) {
            const core = useCore();
            
            if (core.region === 'europe') {
                return this.calculateAmount('price') + this.tps + this.tvq; 
            }

            return this.subtotal + this.tps + this.tvq; 
        },
    },

    // Actions
    actions: {
        addToCart(date_id, slug, type) {
            let cart = JSON.parse(localStorage.getItem('cart'));

            // If cart contain andc help, we delete all of it because the registration process is not the same
            if (cart !== null) {
                const has_andc_help = cart.filter(function(item) {
                    if (item.slug) {
                        return item.slug.includes('service-andc-aide');
                    }
                });

                if (has_andc_help.length > 0) {
                    localStorage.removeItem('cart');
                    localStorage.removeItem('registration');
                    cart = null;
                }
            }
            
            // If item is already there
            if (cart !== null) {
                // const already_in_cart = cart.filter(item => item.slug.includes(slug));

                const already_in_cart = cart.filter(function(item) {
                    if (item.slug) {
                        return item.slug.includes(slug);
                    }
                });

                if (already_in_cart.length > 0) {
                    router.push('/panier');
                    return;
                }
            }
            
            const item = {
                'id': date_id,
                'slug': slug,
                'type': type,
                'quantity': 1,
            };

            if (cart === null) {
                cart = [item];
            } else {
                cart.push(item);
            }

            localStorage.setItem('cart', JSON.stringify(cart));

            this.data.items_count++;
            
            window.scrollTo(0, 0);
            
            router.push('/panier');
        },

        async removeFromCart(slug) {
            let cart = JSON.parse(localStorage.getItem('cart'));

            cart = cart.filter(function(item) {
                return item.slug !== slug;
            });

            this.data.items_count--;

            localStorage.setItem('cart', JSON.stringify(cart));

            await this.fetchCartItemsPrices(JSON.parse(localStorage.getItem('cart')));
        },
        
        async confirmPayment(payment_intent_id) {            
            return new Promise((resolve, reject) => {
 
                const { mutate: confirmPaymentMutation, onDone, onError } = useMutation (confirmPayment, () => ({
                    variables: {
                        cart_slug: localStorage.getItem('cart_slug'),
                        payment_intent_id: payment_intent_id,
                    },
                }));

                confirmPaymentMutation();

                onError(result => {
                    console.log(result);
                });

                onDone(result => {
                    this.data.message = result.data.confirmPayment.message;
                    resolve(result.data.confirmPayment.message);
                });
            });
        },

        async fetchCartItemsPrices(items) {
            return new Promise((resolve, reject) => {      
                const core = useCore();          
                const query = useQuery(fetchCartItemsPrices, { items: items, region: core.region });

                query.onError((error) => reject(error));
                
                query.onResult((result) => {       
                    this.data.items = result.data.fetchCartItemsPrices;
                    resolve();
                });
            });
        },

        async fetchPaymentIntent() {            
            const core = useCore();
            const registration = JSON.parse(localStorage.getItem('registration'));
            const items = JSON.parse(localStorage.getItem('cart'));

            return new Promise((resolve, reject) => {
                const { mutate: createPaymentIntentMutation, onDone, onError } = useMutation (createPaymentIntent, () => ({
                    variables: {
                        region: core.region,
                        items: items,
                        firstname: registration.firstname,
                        lastname: registration.lastname,
                        telephone: registration.telephone,
                        email: registration.email,
                    },
                }));

                createPaymentIntentMutation();

                onError(result => {
                    console.log(result);
                });

                onDone(result => {
                    this.data.client_secret = result.data.createPaymentIntent.client_secret;
                    // console.log(this.data.client_secret);
                    resolve();
                });
            });
        },

        calculateAmount(key) {
            let amount = 0;

            if (this.data.items.length > 0) {
                amount = this.data.items.reduce((sum, item) => sum + item[key], 0);
            }

            return amount;
        },
        
        goToStep(step) {
            this.andcHelpSteps.step1 = false;
            this.andcHelpSteps.step2 = false;
            this.andcHelpSteps.step3 = false;

            window.scrollTo(0, 0);

            if (step == 1) {
                this.andcHelpSteps.num = 1;
                this.andcHelpSteps.step1 = true;
            }

            if (step == 2) {
                this.andcHelpSteps.num = 2;
                this.andcHelpSteps.step2 = true;
            }

            if (step == 3) {
                this.andcHelpSteps.num = 3;
                this.andcHelpSteps.step3 = true;
            }

            if (step == 4) {
                this.andcHelpSteps.num = 4;
                this.andcHelpSteps.step4 = true;
            }

            if (step == 5) {
                this.andcHelpSteps.num = 5;
                this.andcHelpSteps.step5 = true;
            }
        },
    },
});
