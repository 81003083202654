export default [
    { 
        path: '/l-approche-andc', 
        redirect: '/l-approche-andc/tout-savoir-sur-landc'
    },
    {
        path: '/l-approche-andc/tout-savoir-sur-landc',
        name: 'adnc',
        component: () => import('/src/pages/PageAndc.vue'), 
    },
    {
        path: '/l-approche-andc/colette-portelance-et-les-origines-de-landc',
        name: 'andc_origins',
        component: () => import('/src/pages/PageAndcOrigins.vue'),
    },
];
