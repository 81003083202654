export default [
    { 
        path: '/panier', 
        name: 'cart',
        component: () => import('/src/pages/PageCart.vue'),
    },
    { 
        path: '/panier/infos', 
        name: 'cart_infos',
        component: () => import('/src/pages/PageCartInfos.vue'),
    },
    { 
        path: '/panier/paiement', 
        name: 'cart_payment',
        component: () => import('/src/pages/PageCartPayment.vue'),
    },
    { 
        path: '/panier/finalisation', 
        name: 'cart_finish_up',
        component: () => import('/src/pages/PageCartFinishUp.vue'),
    },
    { 
        path: '/panier/confirmation', 
        name: 'cart_confirmation',
        component: () => import('/src/pages/PageCartConfirmation.vue'),
    },
];